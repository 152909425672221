import { ARCX_API_KEY } from '@/constants/envVariables';
import { ArcxAnalyticsProvider as BaseArcxAnalyticsProvider } from '@arcxmoney/analytics';
import React from 'react';
import { isDev } from '@/helpers/appHelpers';

interface Props {
  children: React.ReactNode;
}

const ArcxAnalyticsProvider: React.FC<Props> = ({ children }) => {
  if (ARCX_API_KEY == null || isDev()) {
    return <>{children}</>;
  }

  return (
    <BaseArcxAnalyticsProvider apiKey={ARCX_API_KEY} config={arcxProps()}>
      {children}
    </BaseArcxAnalyticsProvider>
  );
};

const arcxProps = () => {
  if (isDev()) {
    return {
      trackChainChanges: false,
      trackClicks: false,
      trackPages: false,
      trackReferrer: false,
      trackSigning: false,
      trackTransactions: false,
      trackUTM: false,
      trackWalletConnections: false,
    };
  } else {
    return {};
  }
};

export default ArcxAnalyticsProvider;
